// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $maiaWeb-primary: mat.define-palette(mat.$indigo-palette);
// $maiaWeb-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
//$maiaWeb-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".

$my-typography: mat.define-typography-config(
	$font-family: "'Inter'"
);

$maia-petrol: (
	50: var(--maia-petrol-50),
	100: var(--maia-petrol-100),
	200: var(--maia-petrol-200),
	300: var(--maia-petrol-300),
	400: var(--maia-petrol-400),
	500: var(--maia-petrol-500),
	600: var(--maia-petrol-600),
	700: var(--maia-petrol-700),
	800: var(--maia-petrol-800),
	900: var(--maia-petrol-900),
	A100: #7df6ff,
	A200: #4af2ff,
	A400: #17efff,
	A700: #00ebfc,
	contrast: (
		50: #000000,
		100: #000000,
		200: #000000,
		300: #000000,
		400: #ffffff,
		500: #ffffff,
		600: #ffffff,
		700: #ffffff,
		800: #ffffff,
		900: #ffffff,
		A100: #000000,
		A200: #000000,
		A400: #000000,
		A700: #000000
	)
);

$maiaWeb-primary: mat.define-palette($maia-petrol);
$maiaWeb-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$maiaWeb-theme: mat.define-light-theme(
	(
		color: (
			primary: $maiaWeb-primary,
			accent: $maiaWeb-accent,
			//warn: $maiaWeb-warn,
		),
		typography: $my-typography
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include mat.all-component-themes($maiaWeb-theme);

@include mat.core-theme($maiaWeb-theme);
//@include mat.card-theme($maiaWeb-theme);
//@include mat.progress-bar-theme($maiaWeb-theme);
@include mat.tooltip-theme($maiaWeb-theme);
@include mat.form-field-theme($maiaWeb-theme);
//@include mat.input-theme($maiaWeb-theme);
@include mat.select-theme($maiaWeb-theme);
//@include mat.autocomplete-theme($maiaWeb-theme);
@include mat.dialog-theme($maiaWeb-theme);
@include mat.chips-theme($maiaWeb-theme);
//@include mat.slide-toggle-theme($maiaWeb-theme);
//@include mat.radio-theme($maiaWeb-theme);
//@include mat.slider-theme($maiaWeb-theme);
@include mat.menu-theme($maiaWeb-theme);
//@include mat.list-theme($maiaWeb-theme);
//@include mat.paginator-theme($maiaWeb-theme);
@include mat.tabs-theme($maiaWeb-theme);
//@include mat.checkbox-theme($maiaWeb-theme);
//@include mat.button-theme($maiaWeb-theme);
//@include mat.icon-button-theme($maiaWeb-theme);
@include mat.fab-theme($maiaWeb-theme);
//@include mat.snack-bar-theme($maiaWeb-theme);
@include mat.table-theme($maiaWeb-theme);
//@include mat.progress-spinner-theme($maiaWeb-theme);
//@include mat.badge-theme($maiaWeb-theme);
@include mat.bottom-sheet-theme($maiaWeb-theme);
//@include mat.button-toggle-theme($maiaWeb-theme);
@include mat.datepicker-theme($maiaWeb-theme);
//@include mat.divider-theme($maiaWeb-theme);
//@include mat.expansion-theme($maiaWeb-theme);
//@include mat.grid-list-theme($maiaWeb-theme);
//@include mat.icon-theme($maiaWeb-theme);

//@include mat.sidenav-theme($maiaWeb-theme);
//@include mat.stepper-theme($maiaWeb-theme);
//@include mat.sort-theme($maiaWeb-theme);
@include mat.toolbar-theme($maiaWeb-theme);
@include mat.tree-theme($maiaWeb-theme);

.mat-option-text {
	font-family: "Inter", "sans-serif" !important;
}
.mat-select-trigger {
	height: 52px;
}

.mat-select-panel {
	&::-webkit-scrollbar {
		width: 0.6rem;
	}
	&::-webkit-scrollbar-track {
		@apply bg-maia-petrol-900;
		border-radius: 0.625rem;
	}

	&::-webkit-scrollbar-thumb {
		@apply bg-maia-petrol-600;
		border-radius: 0.625rem;
	}
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
	@apply bg-maia-petrol-600;
	@apply text-white;
}
.mat-option:hover:not(.mat-option-disabled) {
	@apply bg-maia-petrol-600;
}
.mat-mdc-tooltip {
	@apply m-0 #{!important};
	.mdc-tooltip__surface {
		@apply bg-maia-petrol-900 #{!important};
		@apply bg-opacity-80 #{!important};
	}
}

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
	min-width: 0 !important;
	padding-right: 0 !important;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.mdc-snackbar__label {
	padding: 0 !important;
	line-height: 1.2 !important;
}
